<template>
    <Modal class="file-picker" :close_click_outside="false" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('cms.pick_file')}}
        </template>
        <template v-slot:body>
            <FileTable @pickedFile="filePicked" />
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import FileTable from "@admin/components/cms/file/FileTable";

export default {
    name: "FilePicker",
    components: {
        Modal,
        FileTable,
    },
    emits: ['close',"pickedFile"],
    props: {
        opened: Boolean,
    },    
    setup(props,context){
        const closeForm = () => {
            context.emit('close');
        }

        const filePicked = (data) => {
            context.emit('pickedFile',data);
        }

        return {
            closeForm,
            filePicked
        }
    }

}
</script>

<style scoped>
.file-picker{
    z-index: 2000;
}
</style>
