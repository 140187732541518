<template>
    <LoadedContent class="file-table" :loaded="isLoaded" :error="isError">
        <BaseTable :columns="columns" :data="data" />
        <PageFooter>
            <BasePagination :loadData="loadData" :getUrl="getPageUrl" />
            <AddItemButton @click="upload = true" :title="$t('cms.upload_new_files')" :icon="'upload'" />
        </PageFooter>
        <teleport to="#app">
            <FileEditForm :baseData="activeItem"  :opened="activeItem !== null" @close="closeModal()" />
            <FilesUploadForm  :opened="upload" @close="upload = false" />
        </teleport>
    </LoadedContent>
</template>

<script>
import BaseTable from "@common/components/crud/BaseTable";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent, provide, ref, } from 'vue';
import LoadedContent from '@common/components/base/LoadedContent';
import { useRouter } from 'vue-router';
import BasePagination from '@common/components/crud/BasePagination';
import AddItemButton from '@admin/components/base/AddItemButton';
import PageFooter from '@admin/components/base/PageFooter';

export default {
    name: "FileTable",
    components: {
        BaseTable,
        LoadedContent,
        FileEditForm: defineAsyncComponent(() => import(/* webpackChunkName: "file-edit-form" */'@admin/components/cms/file/FileEditForm')),
        BasePagination,
        AddItemButton,
        PageFooter,
        FilesUploadForm: defineAsyncComponent(() => import(/* webpackChunkName: "files-upload-form" */'@admin/components/cms/file/FilesUploadForm')),
    },
    props: {
        picker: {
            type: Boolean,
            default: () => true,
        }
    },
    emits: ['pickedFile'],
    setup(props,context){
        provide("crud_store","file")

        const store = useStore();
 
        const i18n = useI18n();

        const router = useRouter();
        
        const loadData = (loadPage = null) => {
            let page = loadPage;
            if(router.currentRoute.value.name === 'files' || router.currentRoute.value.name === 'files_overview'){
                if(page === null && router.currentRoute.value.params.page_num){
                    page = router.currentRoute.value.params.page_num;
                } 
            }
            store.dispatch('file/fetchItems',{page: page});
        };  
        loadData();
        // watchEffect(() => loadData());

        
        const columns = [
            {
                type: "edit_record",
                action: (record) => openModal(record),
            },
            { 
                type: "info",
                header: i18n.t("cms.filename"),
                filter: true,
                key: "title",
                title:i18n.t("cms.filename"),
                class: "w-xl pick",
                required: true,
            },
            { 
                type: "info",
                header: i18n.t("cms.tags"),
                filter: true,
                key: "tags",
                title:i18n.t("cms.tags"),
                class: "w-xxl",
            },
            { 
                type: "info",
                header: i18n.t("cms.file_type"),
                filter: true,
                key: "type",
                title:i18n.t("cms.file_type"),
            },
            {
                type: "loader"
            },
        ];
        if(props.picker){
            columns.push(
                { 
                    type: "button",
                    title: i18n.t('cms.select_file'),
                    icon: () => "arrow_right",
                    content: () => '',
                    action: (record) => {
                        context.emit('pickedFile',record);
                    }
                }
            )
        } else {
            columns.push(
                { 
                    type: "delete",
                }
            )
        }

        // getting data
        const data = computed(() => store.getters['file/getItems']);

        const isLoaded = computed(() => store.getters["file/isLoaded"]);
        
        const isError = computed(() => store.getters["file/isError"]);

        // modal window
        const activeItem = ref(null);
        const openModal = (menuItem) => {
            activeItem.value = menuItem;
        }
        const closeModal = () => {
            activeItem.value = null;
        }
        // pagination
        const getPageUrl = (number) => {
            if(props.picker){
                return null;
            }
            return 'url.project/:project_code/url.files/url.overview/!'+number;
        }

        // upload modal window
        const upload = ref(false);
        
        return {
            columns,
            data,
            isLoaded,
            isError,
            closeModal,
            activeItem,
            openModal,
            getPageUrl,
            upload,
            loadData
        }
    }
}
</script>

<style scoped>
.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
}
::v-deep(.base-table){
    margin: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.2rem;
    min-width: initial;
}
.file-table{
    min-width: 20rem;
    min-height: 20rem;
}
::v-deep(.base-table-row:nth-child(odd) td) {
    background: #fff6e6;
}

::v-deep(.base-table-row:nth-child(even) td) {
    background: #ffe7bb;
}
::v-deep(.base-table-row td) {
    padding: 0.5rem;
}
::v-deep(.base-table-row td.no-padding) {
    padding: 0;
}
::v-deep(.base-table td:first-child) { 
    border-top-left-radius: 1rem; 
    border-bottom-left-radius: 1rem;
}
::v-deep(.base-table td:last-child) { 
    border-top-right-radius: 1rem; 
    border-bottom-right-radius: 1rem;
}
::v-deep(.loader .base-loader) {
    left: 2rem;
    top:0;
}
::v-deep(.overbutton) {
    /* width: 100%; */
    position: relative;
    /* left: 0; */

}

::v-deep(.overbutton div) {
    /* width: 100%; */
    position: absolute;
    left: 0;
    top: 0.5rem;

}


</style>